export interface ICourse {
  prerequisite: string;
  header: ICourseHeader;
  headerSecondSection: ICourseHeaderSecondSection;
  isCourseFlyer: boolean;
  courseOutline: ICourseOutline[];
  faqs: ICourseFaqs[];
  experts: ICourseExperts[];
  studentReviews: string[];
  facts: ICourseFacts[];
  classSchedule: string[];
  showSchedule?:boolean;
  courseStatus: ECourseStatus;
  showTimer: boolean;
  learnMore?: ICourseLearnMore;
  flyer?: ICourseFlyer;
  timerDate?: string;
  isResources?: boolean;
  resourcesYoutubeLink?:string;
  resourcesGitHubLink?:string;
  firstSessionFreeDetails?: ICourseFirstSessionFree;
  videoUrl?: string;
  youtubeVideoUrl?: string;
  isDifferentBullet?: boolean;
  isCourseFee?: boolean;
  courseFeeAmount?: string;
  discountAmount?: string;
  alternativeTag?:string;
}

interface ICourseHeader {
  courseImage?: string;
  courseTitle: string;
  courseDescription: string;
  difficultyLevel: string;
  specialization: string;
  programCategory: string;
  registerButton: string;
  registerLink: string;
  secondaryName: string;
}

interface ICourseHeaderSecondSection {
  starCount: number;
  reviewCount: number;
  registrationDeadline: string;
  parameterOne: string;
  parameterTwo: string;
  parameterThree: string;
  collaborationLogos: ICollaborationLogo[];
  alternativeTag?:string;
}

interface ICollaborationLogo {
  collaborationLogo: string;
  collaborationLogosName: string;
}

interface ISubContent {
  heading: string;
  description: string;
}

interface ISessionContent {
  heading: string;
  description: string;
  subContent: ISubContent[];
}

interface ICourseOutline {
  session: string;
  sessionContent: ISessionContent[];
}

interface ICourseFaqs {
  question: string;
  answer: string;
}

interface ICourseExperts {
  image: string;
  name: string;
  designation: string;
}

interface ICourseFacts {
  fact: string;
  factBold: string;
  author: string;
}

interface ICourseFlyer {
  description: string;
  button: string;
  image: string;
}

export enum ECourseStatus {
  ON_HOLD = "ON_HOLD",
  NEW = "NEW",
  OLD = "OLD",
}

interface ICourseLearnMore {
  description: string;
  button: string;
  articleLink: string;
}

interface ICourseFirstSessionFree {
  description?: string;
  videoUrl?: string;
  slides?: {
    heading: string;
    link: string;
    buttonText: string;
  };
  instruction?: {
    isInstructive?: boolean;
    instructiveText: string;
    instructiveTextSecond: string;
    viewIntruction: string;
    viewIntructionLink: string;
    instructionVideoTopic:string;
    instructionVideoUrl: string;
    videoTwoUrl:string;
    videoTwoTopic:string;
    date: string;
    dateSecond: string;
    heading?: string;
    buttonText: string;
    buttonTextInfo: string;
    buttonLink: string;
    buttonLinkInfo: string;
    moreInformation: string;
  };
}
interface IPolicySubDescription {
  description?: string;
}
interface IPolicyDescription {
  description: string;
  subDescription?: IPolicySubDescription[];
}

interface IPolicySubContent {
  policySubTitle: string;
  policyDescription: IPolicyDescription[];
}

export interface IPolicy {
  policyTitle: string;
  description: string;
  subContent: IPolicySubContent[];
  active: string;
}

export const courses = [
  {
    label: "Cryptography Fundamentals: Securing the Digital World",
    value: "Cryptography Fundamentals: Securing the Digital World",
  },
  {
    label: "Embedded Product Design for IoT",
    value: "Embedded Product Design for IoT",
  },
  {
    label: "{System}Verilog for ASIC/FPGA Design & Simulation",
    value: "{System}Verilog for ASIC/FPGA Design & Simulation",
  },
  {
    label: "Embedded Machine Learning for Edge Computing",
    value: "Embedded Machine Learning for Edge Computing",
  },
  {
    label: "Microcontroller-Based Embedded System Design",
    value: "Microcontroller-Based Embedded System Design",
  },
  { label: "SkillSurf A/L Lessons", value: "SkillSurf A/L Lessons" },
  { label: "Other", value: "Other" },
];
