import { useEffect, useState, Fragment } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import "./CoursePage.scss";
import Bulb from "./assets/bulb.webp";
import OutcomeOoriented from "./assets/icon_1.png";
import HandsExperience from "./assets/icon_2.png";
import StrongFundamentals from "./assets/icon3.webp";
import GuidanceFuture from "./assets/icon4.webp";
import NavBar from "../../shared/components/NavBar/NavBar";
import Footer from "../../shared/components/Footer/Footer";
import difficultyLevel from "./assets/difficulty_level_.svg";
import RightSideArrow from "./assets/right-side-arrow.webp";
import LeftSideArrow from "./assets/left-side-arrow.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination } from "swiper";
import Accordion from "react-bootstrap/Accordion";
import timezz from "timezz";
import parse from "html-react-parser";
import { v4 as uuidv4 } from "uuid";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { DynamicStar } from "react-dynamic-star";
import { ECourseStatus, ICourse } from "../../shared/utils/utils";

interface IProps {
  course: ICourse;
}

const CoursePage = (props: IProps) => {
  const [imageShow, setImageShow] = useState(false);
  const [swipe, setSwipe] = useState<any>();
  const [swipeReview, setSwipeReview] = useState<any>();

  const {
    header,
    headerSecondSection,
    courseOutline,
    faqs,
    experts,
    studentReviews,
    classSchedule,
    prerequisite,
    isCourseFlyer,
    flyer,
    courseStatus,
    learnMore,
    videoUrl,
    youtubeVideoUrl,
    firstSessionFreeDetails,
    isDifferentBullet,
    isResources,
    resourcesYoutubeLink,
    resourcesGitHubLink,
    showTimer,
    timerDate,
    facts,
    isCourseFee,
    courseFeeAmount,
    discountAmount,
    alternativeTag,
    showSchedule,
  } = props.course;

  const [initialRegistrationDateObj, setInitialRegistrationDateObj] = useState({
    days: { innerHTML: "" },
    hours: { innerHTML: "" },
    minutes: { innerHTML: "" },
    seconds: { innerHTML: "" },
  });

  const { days, hours, minutes, seconds } = initialRegistrationDateObj;

  useEffect(() => {
    if (showTimer) {
      if (timerDate) {
        timezz(document.querySelector(".timer")!, {
          date: new Date(timerDate),
        });
        setInitialRegistrationDateObj(
          Object.create(
            timezz(document.querySelector(".timer")!, {
              date: new Date(timerDate),
            })
          ).__proto__.HTMLParts
        );
      }
    }
  }, [showTimer, timerDate]);

  const setCloseRegistration = () =>
    !(
      parseInt(days.innerHTML) === 0 &&
      parseInt(hours.innerHTML) === 0 &&
      parseInt(minutes.innerHTML) === 0 &&
      parseInt(seconds.innerHTML) === 0
    );

  return (
    <div className="course-container fade-in">
      <NavBar background="bg-background" />

      <section className="bg-background " style={{ paddingTop: "60px" }}>
        <div className="container course-section-one d-flex flex-wrap align-items-center">
          <div className="col-12">
            <Row className="align-items-center">
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6">
                {videoUrl ? (
                  <div className="video-wrapper">
                    <video width="560px" height="315px" controls>
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : youtubeVideoUrl ? (
                  <div className="video-wrapper">
                    <iframe
                      width="560"
                      height="315"
                      src={youtubeVideoUrl}
                      title={header.courseTitle}
                      style={{ border: "none" }}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <img
                    src={header.courseImage}
                    className="img-fluid rounded-3"
                    style={{
                      width: "100%",
                      height: "500px",
                      objectFit: "cover",
                    }}
                    alt={header.courseTitle}
                  />
                )}
              </Col>
              <Col className="col-12 col-sm-12 col-md-6 col-lg-6 mt-4 mt-sm-4 mt-md-0 mt-lg-0 ">
                <div className="d-flex align-items-center gap-4 mx-1">
                  <div>
                    <Button
                      className="short-course-btn font-family-class text-white px-3"
                      style={{ fontWeight: 400 }}
                    >
                      {header.programCategory}
                    </Button>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <img src={difficultyLevel} alt="" />
                    <small
                      className="font-family-class text-light-gray light-gray"
                      style={{ fontWeight: 400 }}
                    >
                      {header.difficultyLevel}
                    </small>
                  </div>
                </div>
                <h1 className="col-11 col-lg-11 mt-4 font-family-class text-secondary mx-1">
                  {header.courseTitle}
                </h1>
                {courseStatus === ECourseStatus.OLD ? (
                  <div className="d-flex align-items-center">
                    <div className="font-family-class bg-yellow text-white rounded-1 px-3">
                      {header.specialization}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-1">
                    <div className="font-family-class text-gray">
                      Specialization:
                    </div>
                    <div className="font-family-class bg-secondary text-white rounded-1 px-3">
                      {header.specialization}
                    </div>
                  </div>
                )}

                <p
                  className="mt-3 font-family-class text-light-gray fs-6 mx-1 pe-4"
                  style={{ fontWeight: 400 }}
                >
                  {header.courseDescription}
                </p>
                <Row className="align-items-center g-2 row-cols-auto">
                  {setCloseRegistration() ? (
                    <>
                      {header.registerLink !== "" ? (
                        <Col className="col-12 col-md-auto">
                          <a href={header.registerLink} target={"blank"}>
                            <Button className="register-btn font-family-class text-white">
                              {header.registerButton}
                            </Button>
                          </a>
                        </Col>
                      ) : (
                        <Col>
                          <Button
                            className={`${
                              header.registerButton.includes("Closed!")
                                ? "register-close-btn"
                                : "register-btn"
                            }   font-family-class text-white`}
                          >
                            {header.registerButton}
                          </Button>
                        </Col>
                      )}

                      <Col className="label-btn font-family-class ms-2">
                        {header.secondaryName}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col>
                        <Button className="register-close-btn font-family-class text-white" >
                          {header.registerButton}
                        </Button>
                      </Col>
                      <Col className="label-btn font-family-class text-primary ms-2">
                        {header.secondaryName}
                      </Col>
                    </>
                  )}
                </Row>
                {isCourseFee && (
                  <Row className="align-items-center mt-3 px-2">
                    <Col xs={"auto"} className="font-family-class pe-0 fs-5">
                      Course Fee:
                    </Col>
                    <Col
                      xs={"auto"}
                      className="font-family-class ps-2 pe-2 text-primary fs-5"
                      style={{ fontWeight: "500" }}
                    >
                      {courseFeeAmount}
                    </Col>
                    <Col
                      xs={"auto"}
                      className="font-family-class bg-yellow text-white rounded-2 px-3 m-2"
                    >
                      {discountAmount}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
          <div className="col-12 mt-4 mt-sm-4 mt-md-4 mt-lg-4 mb-3 pt-4 py-4">
            <Card>
              <div className="d-flex flex-wrap align-items-center justify-content-around px-3">
                {showTimer ? (
                  <>
                    <div className="align-items-center">
                      {/* <small
                        className="font-family-class fw-bold"
                        style={{ color: "#FF0000" }}
                      >
                        Registration Closes in
                      </small> */}
                      <div className="d-flex gap-2 text-center timer mt-3 mt-lg-0 mt-md-0">
                        <div>
                          <div className="d-flex align-items-center">
                            <div
                              className="h2 fs-3 text-primary mb-0"
                              data-days
                            ></div>
                            <span className="timer-colon h2 fs-3 text-primary m-0 ">
                              :
                            </span>
                          </div>
                          <small className="text-primary font-family-class">
                            Days
                          </small>
                        </div>
                        <div>
                          <div className="d-flex align-items-center ">
                            <div
                              className="h2 fs-3 text-primary mb-0"
                              data-hours
                            ></div>{" "}
                            <span className="timer-colon h2 fs-3 text-primary m-0 ">
                              :
                            </span>
                          </div>
                          <small className="text-primary font-family-class">
                            Hrs
                          </small>
                        </div>
                        <div>
                          <div className="d-flex align-items-center">
                            {" "}
                            <div
                              className="h2 fs-3 text-primary mb-0"
                              data-minutes
                            ></div>{" "}
                            <span className="timer-colon h2 fs-3 text-primary m-0 ">
                              :
                            </span>
                          </div>
                          <small className="text-primary font-family-class">
                            Min
                          </small>
                        </div>
                        <div>
                          <div
                            className="h2 fs-3 text-primary mb-0"
                            data-seconds
                          ></div>
                          <small className="text-primary font-family-class">
                            Sec
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column py-4 ">
                      <div>
                        <div className="h6 fs-6 font-family-class text-secondary mb-0 px-2">
                          Registration Deadline: <br />{" "}
                          {headerSecondSection.registrationDeadline}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="d-flex flex-column py-4">
                  <div>
                    <div className="h6 fs-6 font-family-class text-secondary mb-0 ">
                      {headerSecondSection.parameterOne}
                    </div>
                    <small className="text-secondary h6 fs-6 font-family-class">
                      {headerSecondSection.parameterTwo}
                    </small>
                  </div>
                </div>
                <div className="d-flex flex-column py-3">
                  {headerSecondSection.alternativeTag !== "" ? (
                    <div className=" px-2 rounded-1 bg-secondary text-center text text-white fs-6 font-family-class my-2 px-3">
                      {headerSecondSection.alternativeTag}
                    </div>
                  ) : null}

                  {headerSecondSection.starCount !== 0 ? (
                    <div>
                      <div>
                        <DynamicStar
                          rating={headerSecondSection.starCount}
                          width={20}
                          height={20}
                          emptyStarColor={"#DBDBDB"}
                        />
                      </div>

                      <small className="text-light-gray font-family-class">
                        ({headerSecondSection.starCount}){" "}
                        {headerSecondSection.reviewCount} Reviews
                      </small>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className=" px-2 rounded-1 bg-secondary text-center text text-white fs-6 font-family-class my-2 px-3">
                          {headerSecondSection.parameterThree}
                        </div>
                        <div>
                          <DynamicStar
                            rating={headerSecondSection.starCount}
                            width={20}
                            height={20}
                            emptyStarColor={"#DBDBDB"}
                          />
                        </div>
                        {headerSecondSection.starCount === 0 ? (
                          <small className="text-light-gray font-family-class">
                            Not rated yet
                          </small>
                        ) : (
                          <small className="text-light-gray font-family-class">
                            {headerSecondSection.reviewCount} Reviews
                          </small>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex text-center py-4 align-items-center">
                  <div>
                    <div className="h6 fs-6 font-family-class text-secondary mb-2">
                      In Collaboration With
                    </div>
                    <div className="d-flex align-items-center flex-wrap justify-content-center gap-3 mt-1">
                      {headerSecondSection.collaborationLogos.map((logo) => {
                        return (
                          <img
                            src={logo.collaborationLogo}
                            style={{ height: "2.2rem" }}
                            alt={logo.collaborationLogosName}
                            key={uuidv4()}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <section className="bg-background">
          {firstSessionFreeDetails ? (
            <div className="container ">
              <div className="row">
                <div className="col-md-12 align-items-center justrify-content-center ">
                  {firstSessionFreeDetails.description && (
                    <p className="fs-5 pb-3 ">
                      {firstSessionFreeDetails.description}
                    </p>
                  )}
                  {firstSessionFreeDetails.videoUrl && (
                    <div className="video-wrapper d-flex align-items-center justify-content-center">
                      <iframe
                        src={firstSessionFreeDetails.videoUrl}
                        allow="accelerometer; autoplay; clipboard-write; 
                encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded"
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row text-center">
                {firstSessionFreeDetails.slides && (
                  <>
                    <div className=" d-flex align-items-center mt-4 flex-wrap justify-content-center">
                      <div className=" d-flex align-items-center col-md-4  flex-wrap">
                        <p className="fs-6 mt-3 mx-3">
                          {firstSessionFreeDetails.slides.heading}
                        </p>
                        <Button
                          className="register-btn font-family-class text-white"
                          href={firstSessionFreeDetails.slides.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {firstSessionFreeDetails.slides.buttonText}
                        </Button>
                      </div>
                    </div>
                    <hr className="mt-4" />
                  </>
                )}
                {firstSessionFreeDetails.instruction && (
                  <>
                    {firstSessionFreeDetails.instruction.isInstructive ? (
                      <>
                        <div className="card pt-5 my-3">
                          {firstSessionFreeDetails.instruction
                            .instructionVideoTopic && (
                            <h4 className="m-0">
                              {
                                firstSessionFreeDetails.instruction
                                  .instructionVideoTopic
                              }
                            </h4>
                          )}

                          {firstSessionFreeDetails.instruction
                            .instructiveText && (
                            <p className="mt-4 mb-0 fs-6">
                              {
                                firstSessionFreeDetails.instruction
                                  .instructiveText
                              }
                              <span style={{ color: "red" }}>
                                <strong>
                                  {firstSessionFreeDetails.instruction.date}
                                </strong>
                              </span>
                            </p>
                          )}

                          {firstSessionFreeDetails.instruction
                            .instructionVideoUrl !== "" ? (
                            <div className="instruction-video">
                              <iframe
                                src={`https://www.youtube.com/embed/${firstSessionFreeDetails.instruction.instructionVideoUrl}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded"
                                className="position-absolute top-50 start-50 translate-middle"
                              />
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {firstSessionFreeDetails.instruction.videoTwoTopic && (
                      <div className="card my-3">
                        <h4 className="mt-5 mb-0">
                          {firstSessionFreeDetails.instruction.videoTwoTopic}
                        </h4>

                        {firstSessionFreeDetails.instruction.videoTwoUrl !==
                        "" ? (
                          <div className="instruction-video">
                            <iframe
                              src={`https://www.youtube.com/embed/${firstSessionFreeDetails.instruction.videoTwoUrl}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded"
                              className="position-absolute top-50 start-50 translate-middle"
                            />
                          </div>
                        ) : null}
                      </div>
                    )}

                    <Row className="align-items-center justify-content-center mt-4">
                      {firstSessionFreeDetails.instruction.buttonLinkInfo && (
                        <Col xs={"auto"}>
                          <Row className="align-items-center">
                            <Col xs={"auto"}>Slides of the Info Session :</Col>
                            <Col>
                              <Button
                                className="register-btn font-family-class text-white"
                                href={
                                  firstSessionFreeDetails.instruction
                                    .buttonLinkInfo
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {
                                  firstSessionFreeDetails.instruction
                                    .buttonTextInfo
                                }
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {firstSessionFreeDetails.instruction.buttonLink && (
                        <Col xs={"auto"} className="mt-3 mt-lg-0">
                          <Row className="align-items-center">
                            <Col xs={"auto"}>Course Outline :</Col>
                            <Col>
                              <Button
                                className="register-btn font-family-class text-white"
                                href={
                                  firstSessionFreeDetails.instruction.buttonLink
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                {firstSessionFreeDetails.instruction.buttonText}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                    {/*  <hr className="mt-5" /> */}
                    <p className=" fs-6 my-3">
                      {
                        firstSessionFreeDetails.instruction
                          .instructiveTextSecond
                      }{" "}
                      <span style={{ color: "red" }}>
                        <strong>
                          {firstSessionFreeDetails.instruction.dateSecond}
                        </strong>
                      </span>
                    </p>
                    <div className=" d-flex align-items-center justify-content-center gap-4 col-md-12 text-center">
                      {firstSessionFreeDetails.instruction.heading && (
                        <p className="fs-6 mt-3 mx-3">
                          {firstSessionFreeDetails.instruction.heading}
                        </p>
                      )}

                      {firstSessionFreeDetails.instruction
                        .viewIntructionLink && (
                        <Row className="align-items-center gx-0 my-2 mb-3">
                          <Col className="col-12 col-md-6">
                            <div>Instructions for Next Steps :</div>
                          </Col>
                          <Col className="col-12 col-md-6 mt-3 mt-md-0">
                            <Button
                              className="register-btn font-family-class text-white"
                              style={{ width: "300px" }}
                              href={
                                firstSessionFreeDetails.instruction
                                  .viewIntructionLink
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {
                                firstSessionFreeDetails.instruction
                                  .viewIntruction
                              }
                            </Button>
                          </Col>
                        </Row>
                      )}

                      {/* <div className="d-flex align-items-center gap-3">
                        <div>
                          <Button
                            className="register-btn font-family-class text-white"
                            style={{ width: "300px" }}
                            href={
                              firstSessionFreeDetails.instruction
                                .viewIntructionLink
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {firstSessionFreeDetails.instruction.viewIntruction}
                          </Button>
                        </div>
                      </div> */}
                    </div>
                    <p className="fs-6  mb-5">
                      <em>
                        {firstSessionFreeDetails.instruction.moreInformation}
                      </em>
                    </p>
                  </>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
      </section>

      {courseStatus === ECourseStatus.NEW ? (
        <section
          className="section-divider-one bg-secondary p-5"
          style={{ borderRadius: "0px 0px 0px 5rem" }}
        >
          <div className="container">
            <Row className="align-items-center pt-4 pb-4 pt-md-5 pb-md-5 d-flex justify-content-between">
              <Col className="col-12 col-md-6 col-lg-7">
                <p
                  className="text-white font-family-class fs-5 mb-0 text-center text-md-start "
                  style={{ fontWeight: 400 }}
                >
                  {learnMore?.description}
                </p>
              </Col>
              <Col className="col-12 col-md-6 col-lg-3 mt-3 mt-md-0 d-flex d-md-block text-center justify-content-center">
                <a
                  className="secondary-button fs-5"
                  style={{ fontWeight: "400" }}
                  target="blank"
                  href={learnMore?.articleLink}
                >
                  {learnMore?.button}
                </a>
              </Col>
            </Row>
          </div>
        </section>
      ) : (
        <></>
      )}

      <section
        className="container course-section-two"
        style={{ paddingTop: "100px" }}
      >
        {header.programCategory === "Workshop" ? (
          <h3 className="font-family-class text-secondary fs-3 mb-4 pb-4">
            Workshop Outline
          </h3>
        ) : (
          <h3 className="font-family-class text-secondary fs-3 mb-4 pb-4">
            Course Outline
          </h3>
        )}

        <Row className="d-flex justify-content-between mx-1">
          <Col className="col-12 col-sm-12 col-md-7 col-lg-7">
            {courseOutline.map((outline) => {
              return (
                <Fragment key={uuidv4()}>
                  <h4 className="font-family-class text-secondary">
                    {outline.session}
                  </h4>

                  <ul className="course-list">
                    {outline.sessionContent.map((content) => {
                      return (
                        <Fragment key={uuidv4()}>
                          <li
                            key={content.heading}
                            className="course-list-item"
                          >
                            <h4 className="font-family-class text-secondary fs-5">
                              {content.heading}
                            </h4>

                            <p className="font-family-class text-light-gray fs-6">
                              {content.description}
                            </p>

                            {content.subContent.length !== 0
                              ? content.subContent.map((subContent) => {
                                  return (
                                    <div key={uuidv4()}>
                                      <div className="font-family-class text-light-gray fs-6">
                                        {subContent.heading}
                                        {isDifferentBullet ? (
                                          <ul>
                                            <li className="font-family-class text-secondary fs-6">
                                              {subContent.description}
                                            </li>
                                          </ul>
                                        ) : (
                                          <ul>
                                            <li>{subContent.description}</li>
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                          </li>
                        </Fragment>
                      );
                    })}
                  </ul>
                </Fragment>
              );
            })}
          </Col>
          <Col className="col-12 col-sm-12 col-md-3 col-lg-3 mt-3 mt-sm-3 mt-md-0 mt-lg-0">
            {isResources && (
              <div>
                <h4 className="font-family-class text-secondary fs-5">
                  Resources
                </h4>
                <ul>
                  <li>
                    <a
                      href={resourcesYoutubeLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Youtube Videos
                    </a>
                  </li>
                  <li>
                    <a
                      href={resourcesGitHubLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Github Repository
                    </a>
                  </li>
                </ul>
              </div>
            )}
            <div>
              <h4 className="font-family-class text-secondary fs-5">
                Prerequisites
              </h4>
              <p className="font-family-class text-light-gray fs-6">
                {parse(prerequisite)}
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {isCourseFlyer ? (
        <section
          className="section-divider-one bg-secondary p-5 "
          style={{ borderRadius: "0px 0px 0px 5rem", marginTop: "4%" }}
        >
          <div className="container">
            <Row className="align-items-center pt-4 pb-4 pt-md-5 pb-md-5 d-flex justify-content-between">
              <Col className="col-12 col-md-6 col-lg-7">
                <p
                  className="text-white font-family-class fs-5 mb-0 text-center text-md-start  "
                  style={{ fontWeight: 400 }}
                >
                  {flyer?.description}
                </p>
              </Col>
              <Col className="modal-section col-12 col-md-6 col-lg-3 mt-3 mt-md-0 d-flex d-md-block text-center justify-content-center">
                <div
                  className="secondary-button fs-5"
                  style={{ fontWeight: "400" }}
                  id="myImg"
                  onClick={() => {
                    setImageShow(true);
                  }}
                >
                  {flyer?.button}
                </div>
              </Col>
            </Row>
          </div>
        </section>
      ) : (
        <></>
      )}

      {classSchedule.length === 0 ? (
        <></>
      ) : (
        <section
          className="container course-section-two py-5"
          
     
        >
          {showSchedule && (
            <>
              <h3 className="font-family-class text-secondary fs-3 mb-4 pb-4 mt-5">
                Class Schedule
              </h3>
              <Row className="d-flex justify-content-between mx-1">
                <Col className="col-12 col-sm-12 col-md-9 col-lg-9">
                  <ul className="course-list">
                    {classSchedule.map((value) => {
                      return (
                        <li key={value} className="course-list-item">
                          <p className="font-family-class text-gray fs-6">
                            {value}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            </>
          )}
        </section>
      )}

      {facts.length === 0 ? (
        <></>
      ) : (
        <section
          className="container"
          style={{ paddingTop: "80px", paddingBottom: "100px" }}
        >
          <Row>
            <Col className="text-center">
              <h3 className="font-family-class text-secondary fs-3">
                Did You Know?
              </h3>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mx-1">
            <Col
              className="col-12 col-md-12 col-lg-6"
              xs={{ order: 2 }}
              md={{ order: 2 }}
              lg={{ order: 1 }}
            >
              {facts.map((fact) => {
                return (
                  <div className="d-flex flex-wrap" key={uuidv4()}>
                    <i className="font-family-class text-gray mt-4 mt-sm-4 mt-md-4 mt-lg-4 fs-6">
                      {fact.fact}
                      <b> {fact.factBold}</b>
                    </i>
                    <small className="font-family-class text-light-gray pt-2">
                      <i>{fact.author}</i>
                    </small>
                  </div>
                );
              })}
            </Col>
            <Col
              className="col-12 col-md-12 col-lg-5 text-center"
              xs={{ order: 1 }}
              md={{ order: 1 }}
              lg={{ order: 2 }}
            >
              <img
                src={Bulb}
                className="img-fluid"
                alt="Vector representing Did You Know facts"
              />
            </Col>
          </Row>
        </section>
      )}

      <section className={`bg-background ${facts.length === 0 ? "`bg-background pt-5" : "bg-background pt-5 mt-5"}`}>
        <div className={`container py-5 ${experts.length <= 5 ? "mb-5" : ""}`}>
          <div className="d-flex align-items-center justify-content-between pb-4">
            <div>
              <h4 className="text-secondary font-family-class fs-3">
                Learn From the Experts
              </h4>
            </div>
            <div className="d-flex gap-2">
              <div onClick={() => swipe?.slidePrev()}>
                <img
                  className="left-side-arrow"
                  src={LeftSideArrow}
                  alt="Arrow representing left carousel"
                />
              </div>
              <div onClick={() => swipe?.slideNext()}>
                <img
                  className="right-side-arrow"
                  src={RightSideArrow}
                  alt="Arrow representing right carousel"
                />
              </div>
            </div>
          </div>
          {experts.length <= 5 ? (
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              onBeforeInit={(swipper) => setSwipe(swipper)}
              pagination={false}
              breakpoints={{
                1276: { slidesPerView: 3, spaceBetween: 10 },
                825: { slidesPerView: 2, spaceBetween: 10 },
              }}
              style={{ width: "100%" }}
            >
              {experts.map((expert) => {
                return (
                  <SwiperSlide key={uuidv4()}>
                    <div
                      className="card border-0 rounded-1 px-xxl-1"
                      style={{ height: "8rem" }}
                    >
                      <div className="card-body px-sm-2 px-md-3 d-flex align-items-center">
                        <div>
                          <img
                            className="rounded-circle cover-image"
                            src={expert.image}
                            style={{
                              objectFit: "fill",
                              width: "60px",
                              height: "60px",
                              maxHeight: "60px",
                              maxWidth: "60px",
                            }}
                            alt={expert.name}
                          />
                        </div>
                        <div className="ps-2 ps-md-3">
                          <h6 className="fs-sm mb-0 font-family-class text-gray">
                            {expert.name}
                          </h6>
                          <h6 className="fs-sm mb-0 pt-1 font-family-class text-light-gray">
                            {expert.designation}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div className="learn-expert">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                onBeforeInit={(swipper) => setSwipe(swipper)}
                grid={{
                  rows: 2,
                }}
                pagination={false}
                modules={[Grid, Pagination]}
                breakpoints={{
                  1276: { slidesPerView: 3, spaceBetween: 10 },
                  825: { slidesPerView: 2, spaceBetween: 10 },
                }}
                style={{ width: "100%" }}
              >
                {experts.map((expert) => {
                  return (
                    <SwiperSlide key={uuidv4()}>
                      <div className="card border-0 rounded-1 px-xxl-1 expert-card">
                        <div className="card-body p-4 px-sm-2 px-md-3 d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                className="rounded-circle cover-image"
                                src={expert.image}
                                style={{
                                  objectFit: "fill",
                                  width: "60px",
                                  height: "60px",
                                  maxHeight: "60px",
                                  maxWidth: "60px",
                                }}
                                alt=""
                              />
                            </div>
                            <div className="ps-2 ps-md-3">
                              <h6 className="fs-sm mb-0 font-family-class text-gray">
                                {expert.name}
                              </h6>
                              <h6 className="fs-sm mb-0 pt-1 font-family-class text-light-gray">
                                {expert.designation}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
        </div>
        <div
          className="bg-secondary py-5"
          style={{ borderRadius: "0px 0px 7rem 0px" }}
        >
          <div className="container ">
            <div className="d-flex align-items-center justify-content-between ">
              <div>
                {headerSecondSection.reviewCount === 0 ? (
                  <h4 className="text-white font-family-class fs-4 mt-3">
                    What Our Other Course Participants Say
                  </h4>
                ) : (
                  <h4 className="text-white font-family-class fs-4">
                    Student Reviews
                  </h4>
                )}
              </div>
              <div>
                {headerSecondSection.reviewCount === 0 ? (
                  <small className="text-gray-3 font-family-class"></small>
                ) : (
                  <small
                    className="text-gray-3 font-family-class"
                    style={{ visibility: "hidden" }}
                  >
                    {headerSecondSection.reviewCount} Reviews
                  </small>
                )}
              </div>
            </div>
            <div
              className="row p-4"
              style={{ paddingTop: "40px", paddingBottom: "100px" }}
            >
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                slidesPerGroup={3}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  520: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                  },
                }}
                onBeforeInit={(swipper) => setSwipeReview(swipper)}
                pagination={false}
                modules={[Pagination]}
                className="mySwiper"
              >
                {studentReviews.map((studentReview) => {
                  return (
                    <SwiperSlide key={studentReview}>
                      <div
                        className="card rounded-2"
                        style={{ height: "100%" }}
                      >
                        <div className="card-body d-flex align-items-center">
                          <p
                            className="text-light-gray font-family-class fs-6 mb-0"
                            style={{ textAlign: "justify" }}
                          >
                            {studentReview}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="d-flex justify-content-center gap-4 mt-4">
                <div
                  onClick={() => swipeReview?.slidePrev()}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-arrow-left-circle-fill text-white"></i>
                </div>
                <div
                  onClick={() => swipeReview?.slideNext()}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-arrow-right-circle-fill text-white"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {faqs.length===0 ? <></> :
            <div
            className="container"
            style={{ paddingBottom: "100px", paddingTop: "100px" }}
            
          >
            <Row>
              {faqs.length > 0 ? (
                <Col className="text-center mt-3">
                  <h3 className="font-family-class text-secondary fs-3">FAQs</h3>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            <div className="d-flex flex-wrap justify-content-center">
              {faqs.map((faq, index) => {
                return (
                  <Fragment key={uuidv4()}>
                    <Card
                      className="mt-4 mt-sm-4 mt-md-4 mt-lg-4 "
                      style={{ width: "60rem" }}
                    >
                      <Accordion>
                        <Accordion.Header>
                          <Card.Body>
                            <Row className="align-items-center">
                              <Col className="col-12 col-sm-12 col-md-3 col-lg-3">
                                <Row className="justify-content-center text-center">
                                  <div className="font-family-class text-primary fs-4">
                                    QUESTION
                                  </div>
                                  <div className="list-bullet">{index + 1}</div>
                                </Row>
                              </Col>
                              <Col className="col-col-12 col-col-sm-12 col-col-md-7 col-col-lg-7 mt-2 mt-sm-2 mt-md-0 mt-lg-0">
                                <p className="font-family-class text-gray fs-5 mt-3">
                                  {faq.question}
                                </p>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p className="font-family-class text-light-gray fs-6">
                            {parse(faq.answer)}
                          </p>
                        </Accordion.Body>
                      </Accordion>
                    </Card>
                  </Fragment>
                );
              })}
            </div>
          </div>
        }
    
      </section>
      <section
        className="container"
        style={{ paddingBottom: "100px", paddingTop: "100px" }}
      >
        <Row>
          <Col className="text-center">
            <h3 className="font-family-class text-secondary fs-3">
              All Our Courses Include
            </h3>
          </Col>
        </Row>
        <Row className="mt-4 mt-sm-4 mt-md-4 mt-lg-4 justify-content-around mx-1">
          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 course-item-width">
            <div>
              <img
                src={OutcomeOoriented}
                alt="Vector representing outcome-oriented approach"
                width={80}
              />
            </div>
            <h5 className="font-family-class text-gray fs-5  mt-2">
              Outcome-oriented approach
            </h5>
            <p className="font-family-class text-light-gray fs-6">
              There are two ways to learn a subject. Learn the concepts, and
              then the student figures out what to do/how to apply the concepts.
              Or, start with the goal of creating something and learn the
              concepts to achieve that goal. We prefer the latter.
            </p>
          </Col>
          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3 mt-sm-2 mt-md-0 mt-lg-0 course-item-width">
            <div>
              <img
                src={HandsExperience}
                alt="Vector representing hands-on experience with industry experts"
                width={80}
              />
            </div>
            <h5 className="font-family-class text-gray fs-5  mt-2">
              Hands-on experience with industry experts
            </h5>
            <p className="font-family-class text-light-gray fs-6">
              Throughout the course, our instructors will work with you in real
              time, help you when you are stuck and improve iteratively. Because
              mastering something is always an iterative, hands-on process, and
              each student has their own approach.
            </p>
          </Col>
          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3 mt-sm-2 mt-md-0 mt-lg-0 course-item-width">
            <div>
              <img
                src={StrongFundamentals}
                alt="Vector representing strong fundamentals"
              />
            </div>
            <h5 className="font-family-class text-gray fs-5 course-include-heading mt-2">
              Strong fundamentals
            </h5>
            <p className="font-family-class text-light-gray fs-6">
              While abstraction is essential, what differentiates a highly
              skilled engineer is an in-depth understanding of the internals.
              Having a strong grasp of fundamentals is critical in achieving
              this goal.
            </p>
          </Col>
          <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3 mt-sm-2 mt-md-0 mt-lg-0 course-item-width">
            <div>
              <img
                src={GuidanceFuture}
                alt="Vector representing guidance for future learning"
              />
            </div>
            <h5 className="font-family-class text-gray fs-5  mt-2">
              Guidance for future learning
            </h5>
            <p className="font-family-class text-light-gray fs-6">
              What can you do with what you learn? We guide you in enhancing
              your knowledge based on what you learned in the course so that you
              can master the subject.
            </p>
          </Col>
        </Row>
      </section>
      <Footer isFinancialInquiries={true} />
      <div>
        <Modal
          show={imageShow}
          onHide={() => {
            setImageShow(false);
          }}
          size="lg"
        >
          <Modal.Body>
            <img src={flyer?.image} alt="" className="img-fluid" />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default CoursePage;
