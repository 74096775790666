import React from "react";
import Logo from "./assets/logo.webp";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import Button from "react-bootstrap/Button";
import "./NavBar.scss";
import { NavLink } from "react-router-dom";

interface Iprops {
  active?: string;
  background?: string;
}

const NavBar = (props: Iprops) => {
  return (
    <div className={`navbar-container ${props.background}`}>
      <div className="navbar-contents">
        <Navbar collapseOnSelect expand="lg">
          <Container fluid>
            <NavLink to={"/"}>
              <img src={Logo} alt="Skill Surf Logo" />
            </NavLink>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav className="d-flex gap-md-0 gap-lg-4 mt-2 mb-3">
                <NavLink
                  to={"/program-catalog"}
                  className={`text-decoration-none  d-flex align-items-center nav-hover  font-family-class fs-6 my-2 ${
                    props.active === "catalog"
                      ? `font-weight-700`
                      : `font-weight-400`
                  }`}
                >
                  Program Catalog
                </NavLink>
                <NavLink
                  to={"/join-the-team"}
                  className={`text-decoration-none d-flex align-items-center nav-hover font-family-class fs-6 my-2  ${
                    props.active === "join-the-team"
                      ? `font-weight-700`
                      : `font-weight-400`
                  }`}
                >
                  Join the Team
                </NavLink>
                <NavLink
                  to={"/about-us"}
                  className={`text-decoration-none d-flex align-items-center nav-hover font-family-class fs-6 my-2  ${
                    props.active === "about"
                      ? `font-weight-700`
                      : `font-weight-400`
                  }`}
                >
                  About Us
                </NavLink>

                <NavLink
                  to={"/contact-us"}
                  className={`text-decoration-none d-flex align-items-center nav-hover font-family-class fs-6 my-2  ${
                    props.active === "contact"
                      ? `font-weight-700`
                      : `font-weight-400`
                  }`}
                >
                  Contact Us
                </NavLink>

                {/* <NavLink
                  to={"/program-catalog"}
                  className="text-decoration-none d-flex align-items-center text-secondary font-family-class fs-6 my-2"
                >
                  About Us
                </NavLink>
                <NavLink
                  to={"/program-catalog"}
                  className="text-decoration-none d-flex align-items-center text-secondary font-family-class fs-6 my-2 "
                >
                  Sign Up
                </NavLink>
                <Button
                  className="login-btn text-white font-family-class  fs-6 my-2"
                  variant="none"
                  style={{ fontWeight: 400, fontSize: "1em" }}
                >
                  LOGIN
                </Button> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBar;
